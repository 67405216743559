<template>
  <div>
    <el-row justify="center">
      <el-col :md="18">
        <el-button type="primary" @click="$router.push({ name: 'users' })"
          >사용자 리스트로 돌아가기</el-button
        >

        <el-card v-if="user" :header="`${user.firstName} ${user.lastName}`">
          <p>이메일: {{ user.email }}</p>
          <p>선금 잔액: $ {{ user.advanceBalance }}</p>

          <el-button type="primary" @click="showDialog = true"
            >선금 충전</el-button
          >

          <h4>선금 충전 내역</h4>
          <el-table :data="advances">
            <el-table-column label="금액">
              <template #default="scope"> $ {{ scope.row.amount }} </template>
            </el-table-column>
            <el-table-column label="충전일시">
              <template #default="scope">
                {{ new Date(scope.row.createdAt).toLocaleString() }}
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="scope">
                <el-button size="mini" @click="handleDeleteAdvance(scope.row)">
                  삭제
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <h4>주문 내역</h4>
          <el-table :data="user.orders.filter((o) => !o.archived)">
            <el-table-column label="주문일">
              <template #default="scope">
                {{ new Date(scope.row.createdAt).toLocaleString() }}
              </template>
            </el-table-column>
            <el-table-column label="총금액">
              <template #default="scope">
                $ {{ scope.row.totalPrice }}
              </template>
            </el-table-column>
            <el-table-column label="결제여부">
              <template #default="scope">
                {{ scope.row.paid ? "결제완료" : "결제미완료" }}
              </template>
            </el-table-column>
            <el-table-column prop="paymentType" label="결제방식" />
          </el-table>
        </el-card>
        <el-alert v-if="message" :title="message" type="error" />
      </el-col>
    </el-row>

    <el-dialog v-model="showDialog" title="선금 충전">
      <el-input v-model="amount" type="number" placeholder="Please input">
        <template #prefix>$</template>
      </el-input>
      <el-button type="primary" @click="chargeAdvance()" :loading="loading"
        >충전하기</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import UserService from "../services/UserService";
import AdvanceService from "../services/AdvanceService";

export default {
  name: "EditUser",
  data() {
    return {
      showDialog: false,
      loading: false,
      user: null,
      amount: 0,
      advances: [],
      message: "",
    };
  },
  methods: {
    async chargeAdvance() {
      ElMessageBox.confirm("계속 하시겠습니까?", "선금 충전", {
        confirmButtonText: "네",
        cancelButtonText: "아니요",
        type: "info",
      })
        .then(async () => {
          this.loading = true;
          try {
            const response = await AdvanceService.create({
              amount: this.amount,
              userId: this.user.id,
            });
            console.log(response.data);
            this.loading = false;
            this.showDialog = false;
            this.$message.success("성공적으로 충전되었습니다.");

            await this.retrieveUser();
            await this.retrieveAdvances();
          } catch (e) {
            console.log(e);
            this.loading = false;
            this.$message.error(
              "에러가 발생했습니다. 잠시 후 재시도 해주세요."
            );
          }
        })
        .catch(() => {});
    },
    async retrieveAdvances() {
      try {
        const response = await AdvanceService.getAllByUserId(this.user.id);
        this.advances = response.data;
        console.log(response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async retrieveUser() {
      const userId = this.$route.params.id;
      try {
        const response = await UserService.get(userId);
        console.log(response.data);
        this.user = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async handleDeleteAdvance(advance) {
      try {
        await this.$confirm("정말로 선금 충전 내역을 삭제하시겠습니까?");
        await AdvanceService.delete(advance.id);
        this.$message.success("선금 충전 내역이 삭제되었습니다.");
        await this.retrieveUser();
        await this.retrieveAdvances();
      } catch (e) {
        console.log(e);
        this.message = e?.response?.data;
      }
    },
  },
  async created() {
    await this.retrieveUser();
    await this.retrieveAdvances();
  },
};
</script>

<style>
</style>